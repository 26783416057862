.myVideo {
  margin: auto;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  visibility: visible;
  opacity: 1;
  min-width: 100%;
  min-height: 100%;
}

.containerVideo {
  position: absolute;
  z-index: -1;
  inset: 0px;
  overflow: hidden;
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-image: none;
  width: 100%;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.content {
  visibility: visible;
  animation-delay: 0.6s;
  animation-name: fadeIn;
  left: 10%;
  position: absolute;
  bottom: 10%;
  width: 60%;
  max-width: 1400px;
  background-color: rgba(32, 32, 32, 0.438);
  padding: 10px;
  border-radius: 10px;
}
